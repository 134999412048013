<template>
  <div>
    <headers />
    <div class="FAQ-page">
      <div class="container">
        <div class="txt-title">
          <div class="left-box"></div>
          <span>FAQ</span>
        </div>
        <div class="list-box" v-for="(item, index) in list" :key="index">
          <div class="ask">
            <div class="tip">问</div>
            <div class="txt" :id="'ask-title-' + item.id">{{ item.ask }}</div>
          </div>
          <div class="answer">
            <div class="tip">答</div>
            <!--
            <template v-if="index == 1 || index == 3">
            -->
            <template>
              <div class="txt">
                <div v-html="item.answer"></div>
                <div v-for="(item1, index1) in item.txtList" :key="index1">
                  {{ item1 }}
                </div>
              </div>
            </template>
            <!--
            <template v-else>
              <div class="txt">
                {{ item.answer }}
              </div>
            </template>
            -->
          </div>
        </div>
      </div>
    </div>
    <right-icon />
  </div>
</template>

<script>
import Headers from '@/components/Header/index.vue'
import RightIcon from '@/components/right-icon/index.vue'
import list from './list.js'

const selectText = (ele) => {
  if (document.body.createTextRange) {
    let range = document.body.createTextRange()
    range.moveToElementText(ele)
    range.select()
    range = null
  } else if (window.getSelection) {
    let selection = window.getSelection()
    let range = document.createRange()
    range.selectNodeContents(ele)
    selection.removeAllRanges()
    selection.addRange(range)
    range = null
    selection = null
  }
}

export default {
  name: 'Faq',
  components: {
    Headers,
    RightIcon
  },
  props: {},
  data() {
    return {
      list,
    }
  },
  created() { },
  mounted() {
    this.goToAppoint()
  },
  methods: {
    goToAppoint() {
      this.$nextTick(() => {
        const { 
          query: { id }
        } = this.$route
        const askDomeId = `#ask-title-${id}`
        const element = document.querySelector(askDomeId)
        if (element) {
          window.scrollTo({
            top: element.offsetTop - 100, // 滚动距离
            behavior: 'smooth'
          })
          selectText(element)
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.FAQ-page {
  display: flex;
  justify-content: center;
  background: #f5f6fa;
  padding: 140px 0 80px;
  .container {
    width: 1200px;
  }
  .txt-title {
    display: flex;
    margin-bottom: 40px;
    .left-box {
      width: 4px;
      height: 26px;
      border-radius: 2px;
      background-color: #1b4eff;
      margin-right: 16px;
    }
    span {
      font-size: 28px;
      color: #373e4f;
      font-weight: 700;
      margin-top: -7px;
    }
  }
  .list-box {
    background: #ffffff;
    border-radius: 4px;
    padding: 30px 30px 35px;
    .tip {
      min-width: 28px;
      height: 28px;
      background: #3676ff;
      opacity: 0.8;
      border-radius: 4px;
      color: #fff;
      line-height: 28px;
      text-align: center;
      margin-right: 10px;
    }
    .ask {
      display: flex;
      margin-bottom: 22px;
      .txt {
        font-size: 16px;
        color: #373e4f;
        line-height: 30px;
        font-weight: bold;
      }
    }
    .answer {
      display: flex;
      .tip {
        background: #48D9A1;
      }
      .txt {
        font-size: 16px;
        font-weight: 400;
        color: #373e4f;
        line-height: 30px;
      }
    }
  }
}
</style>
