const FQAList = [
  {
    id: 0,
    ask: '什么是闲置链接？',
    answer: '长期在亚马逊平台无店铺销售的链接，也就是无主链接，俗称僵尸链接',
  },
  {
    id: 1,
    ask: '为什么会有闲置链接？',
    answer: '闲置链接产生主要有以下几个来源：',
    txtList: [
      '1、店铺主动停止销售，listing突然退出舞台，这种listing是非常好的；',
      '2、产品更新换代不卖，卖家转行不做的或者直接下架的链接；',
      '3、被亚马逊识别有风险ASIN，短期冲上去赚一波就不卖了的；',
      '4、卖家认为无价值ASIN，评分低于3颗星不卖了的；',
      '5、店铺被停掉销售权、listing不让卖或者被close掉、店铺申诉期间等等的链接。',
    ],
  },
  {
    id: 2,
    ask: '什么是优质的闲置链接？',
    answer:
      '亚马逊上无品牌或者是小品牌，并且已经停售超过2年以上，评分整体在4.3颗星以上，首页无差评或者只有极少差评的链接。',
  },
  {
    id: 3,
    ask: '为什么要用闲置链接？',
    answer: '你不用，你的竞争对手都会用，大量新加入的卖家也会用，我们必须要做好攻守相依，合并/同步闲置链接是目前市场反应最快，也是最有效果，但是相对来说风险不那么高的一种操作方式。',
  },
  {
    id: 4,
    ask: '闲置链接有什么用途？',
    answer:
      '卖家本国和跨站点销售时，新品缺评价，老品评分低时，卖家可以用来同步国际评价合并或者直接销售，提高Listing评分和销量，闲置链接合并最好要合规变体',
  },
  {
    id: 5,
    ask: '闲置链接有什么价值？',
    answer:'',
    txtList: [
      '1、可以短期内增加很多vp评论，提高listing的转化，提高新品的数据表现，维护好老品的评价，减少推广的费用，提高产品利润，迅速激活链接；',
      '2、可以迅速查询到所经营类目的与之相匹配的闲置链接进行处理，减少大量的时间成本，提高团队工作效率；',
      '3、可以同步跨站点合并好评和直接销售，卖家跨站点销售时，新品缺评价，老品评分低时，卖家可以用来同步国际评价合并，提高Listing评分。卖家国际同步销售时，新品缺评价，可以直接同步到对应站点开展销售，适合于铺货销售，特别是小站点泛铺，可以同步美国闲置链接直接到对应站点销售',
    ]
  },
  {
    id: 6,
    ask: '闲置链接有哪些应用场景？',
    answer:'主要有三大应用场景：',
    txtList: [
      '场景一：卖家新品没有评价，数据表现不佳，又不敢测评，此时卖家可以用来本国链接合并，提高新品表现；卖家新品老品链接评分不好，整体数据表现下滑，此时可以用来合并维护老品评价，建议最好要合规变体。',
      '场景二：卖家跨站点销售时，新品缺评价，老品评分低时，卖家可以用来同步国际评价合并，提高Listing评分，国际评价同步建议最好要合规变体。',
      '场景三：卖家国际同步销售时，新品缺评价，可以直接同步到对应站点开展销售，适合于铺货销售，特别是小站点泛铺，可以同步美国闲置链接直接到对应站点销售。',
      '以上最好要注意同步链接时要注意各站点品牌是否有侵权事宜。',
    ],
  },
  {
    id: 7,
    ask: '我需要怎么用闲置链接？',
    answer:
      '可以直接在暗度上搜搜关键词，然后找到对应的闲置链接，然后用对应的链接ASIN直接跟卖上架或者直接同步到对应站点，如果是合并最好是要类目一致，等链接上架成功后可以进行合并。',
  },
  {
    id: 8,
    ask: '我可以把闲置链接同步到英国，日本，沙特等国家去合并吗？',
    answer:
      '可以的，只要我们系统里面有查询到的，对应国家没有用过的都可以，不过要注意下对应国家是否有品牌，最好用没有品牌的。',
  },
  {
    id: 9,
    ask: '我可以把闲置链接同步到沙特，澳洲，新加坡等国家去卖吗？',
    answer:
      '可以的，其实这个对于铺货卖家是很有优势的，只需要花一点点费用，就可以找到可以销售的产品直接同步到对应国家去卖即可，也不用担心评价的问题，可以直接同步销售，因为是闲置链接所以也不会影响对应美国的链接。',
  },
  {
    id: 10,
    ask: '哪些类型的卖家会用闲置链接？',
    answer: '',
    txtList: [
      '卖家一：老练型，果断开干，迅速占领坑位，打造Listing;',
      '卖家二：有效型，先做先占坑，把评价多、评分高的listing先找到，使用有效，然后迅速铺开并据为己有了再说;',
      '卖家三：胆大型，找到了一种有效的模式，果断铺开，目的直接效果快，不干白不干，出了问题大不了重来;',
      '卖家四：谨慎观望型：先让其他人先试，观望一段时间，不错就跟着上;',
      '卖家五：纯白帽型，坚决不用任何刷单、测评和闲置链接等方式，佛系运营猥琐发育。',
    ],
  },
  {
    id: 11,
    ask: '在筛选闲置链接的时候，优质闲置链接最看重哪些指标？',
    answer:
      `评判一条闲置链接好坏，有以下几个指标：评分、评价数、停售时间、上线时间、首页差评情况、是否知名品牌、是否已被人用过、类目是否相关。一般来说，我建议最好选择评分4.5分以上，评价数5以上、停售时间2年以上、首页基本没有太多差评、没有被人用过，也是非知名大牌，如果只是一般商标也没什么问题的，另外类目尽量跟你卖的产品相关。<br/>
      <div style="color:#ff0000">还有，如果这个闲置链接能够同步的国家越多越好。</div>`,
  },
  {
    id: 12,
    ask: '暗度平台闲置链接的评判标准是什么？',
    answer:
      '评判闲置链接好坏我们设立了12项指标，包含用户评分、评价数、类目、首页评分、评价占比、产品上架时间、产品最后评论时间、品牌名称、是否知名品牌、产品是否已被人用过、链接中图片/视频数量、同步的国家数等，最终评价链接的好坏是由所有12个因素加权后综合的结果。排名越靠前的链接，价值越大。',
  },
  {
    id: 13,
    ask: '暗度平台有多少条闲置链接？',
    answer:
      '从2017年9月开始，我们开始了闲置链接的收集与合并技术，截止到2021年8月1日，我们暗度共收录整理了946万条覆盖亚马逊美国站全类目链接。',
  },
  {
    id: 14,
    ask: '你们暗度平台有什么优势？',
    answer:'',
    txtList: [
      '一般来说，大家在上新品时需要合并链接，你如果找服务商去合并，一是他人知道你的店铺，可能存在安全隐患；二是服务商一般按照8-10元每个评价收取链接费用，一条200个评价的链接就需要近2000元，价格太贵。',
      '此外如果自己去亚马逊平台搜取，一是非常耗费时间，一个老手一天可能都找不到一条合适的，好不容易找到了一条，很可能还是别人用过的；二是找到的很可能不是最优质、最适合自己产品的链接。',
      '另外，如果你专门买特定的闲置链接抓取软件，一套几万元太贵。',
      '在这样情况下，我们提供了价格非常实惠的查询软件暗度，你通过暗度找到一条非常优质的链接才只需要几块钱到几十块钱不等，非常便宜，能为大家打造新品提供很大便捷。',
    ]
  },
  {
    id: 15,
    ask: '请问通过暗度软件找到合适的闲置链接后，怎么修改链接或者同步到其它站点？',
    answer:'',
    txtList: [
      '关于闲置链接的修改、同步技术，网上有很多的分享，大家可以自己去看下。',
      '另外，我们也有一些参考的内容，大家可以自己去学习、学习如何改。自己学会后，也不用担心你们自己店铺被服务商知道了，我们暗度平台是一个链接数据信息收集、筛选平台，主要作用是帮大家低成本、高效得筛选出最适合自己类目、自己新品的闲置链接。',
    ]
  },
  {
    id: 16,
    ask: '请问合并闲置链接有没有什么风险？',
    answer: '我们所有筛选出来的链接都是停售2年以上的，而且都是没有品牌或者普通商标，我们自己也是做亚马逊店铺的，我们自己店铺就是这么用的。通过合并闲置链接获得的评价，比直接找人测评，无论是风险程度、还是费用高低，这个性价比高得多，总体而言这个是目前迅速获取评价风险相对可控的最佳方式之一。',
    txtList: [
      '但是以下两点需要注意：',
      '1、合并一定要先将闲置链接品牌改成与自己要做的品牌一致，极少数无法更改品牌的话，是没办法合并的。',
      '2、建议最好类目也要一致，不然很容易判变体违规，最好是找有变体类目的产品。',
    ]
  },
  {
    id: 17,
    ask: '我查询了自己类目的一个关键词，出来了30条链接，请问我选择哪条链接合适？',
    answer:'暗度给您筛选出的链接，是我们从900多万条链接中筛选出来的，是最适合您这个类目和关键词的链接；另外，关于链接排序，原则上是得分越高的链接，价值越大！',
    txtList: [
      '我们将所有链接按照得分划分为了99、49、29、9、4区域，大家可以根据自己情况选择适合的闲置链接。',
    ],
  },
  {
    id: 18,
    ask: '我选的闲置链接里面有图片/视频，请问有影响吗？',
    answer:
      '一般来说，如果一条闲置链接评价有100个以上，有5张以上的图片，这个是正常的，没有太大的影响。反而是如果评价数太少了意义不大；其实闲置链接最重要的影响因素是链接的评分、评价数、首页差评情况、停售时间。',
  },
  {
    id: 19,
    ask: '我选的闲置链接有一个商标，请问有影响吗？',
    answer:
      '首先，一般来说，只要你的这个商标不是欧美知名品牌即可；另外，我们系统已经做了一道筛选，把欧美知名品牌已经从链接库中踢出。',
  },
  {
    id: 20,
    ask: '我选的链接，发现已经被其他卖家用过，怎么办？',
    answer:
      '我们建立了完善的反馈机制，您在购买了链接后，如发现该链接在美国站点已经被人合并过了，请务必在链接下单后的2小时内，通过暗度平台网站反馈凭证，我们将在24小时内（工作日之内）答复，申诉成功则金币退回。如果超过2小时再反馈，则默认链接正常交易。',
  },
  {
    id: 21,
    ask: '暗度平台怎么充值？',
    answer:
      '直接可以扫码充值，充值后会转为平台对应的金币，1金币相当于1元。另外，在充值赠送金币的活动中，金币的消耗顺序，先消耗充值的金币，再消耗赠送的金币。',
  },
  /* {
    ask: '',
    answer:
      '',
  }, */
]

export default FQAList
